import React, { useState, useEffect } from 'react'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import PageIntro from '../components/PageIntro'
import Newsletter from '../components/Newsletter'

import SpinnerSVG from '../assets/svg/spinner.svg'

const FeedItem = ({ entry }) => {
  return (
    <li>
      <a
        className="rsswidget"
        href={entry.link}
        target="__blank"
        rel="noopener noreferrer"
      >
        <strong>{entry.title}</strong>
      </a>
      {' • '}
      <span className="rss-date">
        <time dateTime={entry.date}>{entry.pubDate}</time>
      </span>
    </li>
  )
}

const FsaAlertsPage = () => {
  // Initialize state with empty array so server and client renders match
  const [feed, setFeed] = useState([])

  useEffect(() => {
    // Set feed to state
    fetch('/api/fsa-alerts')
      .then((response) => response.json())
      .then(({ data }) => setFeed(data))
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <Page className="p-fsaAlerts has-fingerPrints">
      <SEO
        title="Erudus presents the latest FSA Alerts"
        description="Food Alerts direct from the Food Standards Authority right here on Erudus. Keep up to date with all the latest Food Alerts issued by the FSA."
      />
      <Schema
        breadcrumbs={[
          {
            name: 'Erudus Food Alerts',
            uri: '/fsa-alerts',
          },
        ]}
      />

      <PageIntro
        title={`Food Recall Alert`}
        text={`Latest Allergy Alerts from the Food Standards Agency`}
        image={`about/About-Icon`}
      />

      <section className="c-section c-section--rssAlerts">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-rssAlerts">
              <div className="row">
                <div className="col-12 col-md-3">
                  <h2>
                    <a href="https://www.food.gov.uk/rss-feed/alerts">
                      RSS Food Alerts
                    </a>
                  </h2>
                </div>

                <div className="col-12 col-md-9">
                  <ul id="rssAlertsList" className="c-rssAlertsList">
                    {feed.length === 0 && (
                      <SpinnerSVG className="mr-2" width="20" />
                    )}

                    {feed.map((entry, i) => (
                      <FeedItem key={i} entry={entry} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default FsaAlertsPage
